// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL:"https://apim-plm-d-ncus-1.azure-api.net/getContentHubFile/v1/getContentHubFile/",
  issuer: 'https://ebiz.oktapreview.com/oauth2/auslle1jli7NKpqm70h7',
  clientId: '0oa16bt01jpXP5AgR0h8'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
