import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
} from '@angular/common/http';
import { catchError, Observable, scan, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetFileService {
  constructor(private http: HttpClient) {}

  getFile(url: string): Observable<Download> {
    const headers = new HttpHeaders().set(
      'authorization',
      'Bearer ' +
        JSON.parse(localStorage.getItem('okta-token-storage') || '').accessToken
          .accessToken
    );

    return this.http
      .get(url, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
        headers,
      })
      .pipe(
        download((blob) => blob),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}

export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  progress: number;
  content: Blob | null;
  headers?:HttpHeaders;
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

export function download(
  saver?: (b: Blob) => void
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan(
        (previous: Download, event: HttpEvent<Blob>): Download => {
          if (isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : previous.progress,
              state: 'IN_PROGRESS',
              content: null,
            };
          }
          if (isHttpResponse(event)) {
            console.log(event)
            if (saver && event.body) {
              saver(event.body);
            }
            return {
              progress: 100,
              state: 'DONE',
              content: event.body,
              headers:event.headers
            };
          }
          return previous;
        },
        { state: 'PENDING', progress: 0, content: null }
      )
    );
}
