import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';

import { environment } from '../../environments/environment';

import { GetFileService } from './get-file.service';
import {
  NgxExtendedPdfViewerService,
  pdfDefaultOptions,
} from 'ngx-extended-pdf-viewer';

function blobToBase64(blob: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(',')[1];
      resolve(base64);
    };
    reader.readAsDataURL(blob.content);
  });
}

@Component({
  selector: 'app-get-file',
  templateUrl: './get-file.component.html',
  styleUrls: ['./get-file.component.css'],
})
export class GetFileComponent implements OnInit {
  public src: any;
  public pageActual: any;
  fileName: any;
  displayBanner: boolean | undefined;
  public spreadMode: 'off' | 'even' | 'odd' = 'off';
  page: any;
  color: ThemePalette = 'accent';

  constructor(
    private getFileService: GetFileService,
    private pdfViewerService: NgxExtendedPdfViewerService
  ) {
    this.src = null as any;
    pdfDefaultOptions.ignoreDestinationZoom = true;
  }

  ngOnInit(): void {
    let _hash = localStorage.getItem('hash');
    this.downloadFile(environment.apiURL + '?hash=' + _hash);
  }

  downloadFile(url: string) {
    this.getFileService.getFile(url).subscribe(
      (response) => {
        
        this.displayBanner = this.messageBar(false);
        this.src = response;
        blobToBase64(response).then(
          (base64String) => {
            this.src = base64String;
            this.fileName = response.headers?.get("content-disposition")?.split(';')[1].split("=")[1];
          }
        );
     },
      (error) => {
        console.log(error);
        this.displayBanner = this.messageBar(true);
      }
    );
  }

  messageBar(value: boolean) {
    return value;
  }

  closeMessage() {
    this.displayBanner = this.messageBar(false);
  }

  public onPageRendered(): void {
    if (!this.pdfViewerService.isRenderQueueEmpty()) {
      // try again later when the pages requested by the pdf.js core or the user have been rendered
      setTimeout(() => this.onPageRendered(), 100);
    }

    const pagesBefore = this.spreadMode === 'off' ? 2 : 2;
    const pagesAfter = this.spreadMode === 'off' ? 2 : 5;
    let startPage = Math.max(this.page - pagesBefore, 1);
    let endPage = Math.min(
      this.page + pagesAfter,
      this.pdfViewerService.numberOfPages()
    );

    const renderedPages = this.pdfViewerService.currentlyRenderedPages();

    for (let page = startPage; page <= endPage; page++) {
      const pageIndex = page - 1;
      if (this.pdfViewerService.hasPageBeenRendered(pageIndex)) {
        this.pdfViewerService.addPageToRenderQueue(pageIndex);
        break; // break because you can request only one page at a time
      }
    }
  }
}
