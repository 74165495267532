import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';

import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: any = null;
  countdown?: any = null;
  
  public isAuthenticated$!: Observable<boolean>;

  constructor(cd: ChangeDetectorRef, private keepalive: Keepalive, private idle: Idle, private _router: Router, private _oktaStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,private route: ActivatedRoute) {
    idle.setIdle(1200000);
    idle.setTimeout(1200000);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    
    idle.onIdleEnd.subscribe(() =>{ 
      this.idleState = "NOT_IDLE";
      console.log(`${this.idleState} ${new Date()}`)
      this.countdown = null;
      cd.detectChanges();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
 
    });

    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    keepalive.interval(1200000);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    

    var angularPlugin = new AngularPlugin();
        const appInsights = new ApplicationInsights({ config: {
        instrumentationKey: '815b5602-85e8-44d0-897b-ae04d5b14114',
        extensions: [angularPlugin],
        extensionConfig: {
            [angularPlugin.identifier]: { router: this._router }
        }
        } });
        appInsights.loadAppInsights();
   }

   reset() {
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }
  public ngOnInit(): void {
    this.reset();
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    this.route.queryParams
        .subscribe(params => {
          if (typeof params['hash'] !== 'undefined') {

            localStorage.setItem('hash', params['hash']);
          }
        });

        // this._oktaAuth.isAuthenticated().then((val) => {
        //   if(val === false){
        //     this._oktaAuth.signInWithRedirect().then(
        //       _ => this._router.navigate(['/getFile'])
        //     );
        //   }
        // })
      //   setTimeout(() => {
      //     this._oktaAuth.signInWithRedirect({ originalUri: '/' });
      //     localStorage.setItem('downloading', '0');
      // }, 5000);
  }

  
  public async signIn() : Promise<void> {
    await this._oktaAuth.signInWithRedirect().then(
      _ => this._router.navigate(['/getFile'])
    );
    //await this._oktaAuth.signInWithRedirect({ originalUri: '/' })
  }

  public async signOut(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  
}
