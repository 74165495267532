import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ProfileComponent } from './profile/profile.component';

import { AuthInterceptor } from './auth.interceptor';
import { GetFileComponent } from './get-file/get-file.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import {CommonInterceptor} from './common.interceptor'

import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SafeHTMLPipe } from './safe-html.pipe';

import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const oktaAuth = new OktaAuth({
  //TODO: move to env setting below code same for QA, DEV due to diff setting 
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: window.location.origin+'/authorization-code/callback'
})



@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    GetFileComponent,
    SafeHTMLPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    OktaAuthModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  providers: [ {
    provide: ErrorHandler,
    useClass: ApplicationinsightsAngularpluginErrorService
  },{ provide: OKTA_CONFIG, useValue: { oktaAuth } },{ provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
