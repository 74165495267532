<div  class="idle-container">
<div  class="idle-layout">
<div [ngClass]="idleState === 'NOT_IDLE' ? 'online' : 'offline'"></div>
<div *ngIf="idleState === 'NOT_IDLE'"><h2>Online</h2></div>
<div *ngIf="idleState !== 'NOT_IDLE'"><h2>Offline</h2></div>


</div>
<small class="last-ping" *ngIf="lastPing !== null">LAST_PING: {{lastPing | date :'short'}}</small>
</div>
<router-outlet></router-outlet>