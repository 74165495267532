import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { GetFileComponent } from './get-file/get-file.component';

import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  { path: 'authorization-code/callback', component: OktaCallbackComponent },
  {path: 'profile', component: ProfileComponent, canActivate: [OktaAuthGuard]},
  {path: 'getFile', component: GetFileComponent, canActivate: [OktaAuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
