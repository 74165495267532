<div class="banner">
  <div class="banner__logo">
    <img src="../assets/kc_logo.jpg" alt="KC_Logo" />
  </div>
  <div class="banner__Header">
    <div class="align-center">
      <h1>PLM Spec File Viewer</h1>
      <small>{{ fileName }}</small>
    </div>
  </div>
  <div class="banner__spacer"></div>
</div>


<mat-progress-bar
  *ngIf="src"
  [mode]="src.state == 'PENDING' ? 'indeterminate' : 'determinate'"
  [value]="src.progress"
  [color]="color"
>
</mat-progress-bar>
<div *ngIf="displayBanner" class="msgBanner">
  Failed to load file
  <div class="close" (click)="closeMessage()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16px"
      viewBox="0 0 329.26933 329"
      width="16px"
    >
      <path
        d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
      />
    </svg>
  </div>
</div>
<ngx-extended-pdf-viewer
  [base64Src]="src"
  [height]="'85vh'"
  [useBrowserLocale]="true"
  [filenameForDownload]="fileName"
  [zoom]="'page-width'"
  [(page)]="page"
  [textLayer]="true"
  (pageRendered)="onPageRendered()"
>
  >
</ngx-extended-pdf-viewer>
